@font-face {
  font-family: 'azino-icon';
  src:  url('../fonts/azino-icon.eot?7icgw');
  src:  url('../fonts/azino-icon.eot?7icgw#iefix') format('embedded-opentype'),
    url('../fonts/azino-icon.ttf?7icgw') format('truetype'),
    url('../fonts/azino-icon.woff?7icgw') format('woff'),
    url('../fonts/azino-icon.svg?7icgw#azino-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="azino-icon-"], [class*=" azino-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'azino-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.azino-icon-calling:before {
  content: "\e912";
}
.azino-icon-email1:before {
  content: "\e913";
}
.azino-icon-support:before {
  content: "\e90f";
}
.azino-icon-contact:before {
  content: "\e90d";
}
.azino-icon-address:before {
  content: "\e90e";
}
.azino-icon-email:before {
  content: "\e900";
}
.azino-icon-magnifying-glass:before {
  content: "\e901";
}
.azino-icon-telephone:before {
  content: "\e902";
}
.azino-icon-water-bottle:before {
  content: "\e903";
}
.azino-icon-hamburger:before {
  content: "\e904";
}
.azino-icon-reading-book:before {
  content: "\e905";
}
.azino-icon-stethoscope:before {
  content: "\e906";
}
.azino-icon-confirmation:before {
  content: "\e907";
}
.azino-icon-share:before {
  content: "\e908";
}
.azino-icon-charity:before {
  content: "\e909";
}
.azino-icon-dove:before {
  content: "\e90a";
}
.azino-icon-family:before {
  content: "\e90b";
}
.azino-icon-pin:before {
  content: "\e90c";
}
.azino-icon-pin1:before {
  content: "\e910";
}
.azino-icon-clock:before {
  content: "\e911";
}
