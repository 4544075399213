/* // contador de visita */
.visor_counter {
    position: fixed;
    width: 9%;
    bottom: 40px;
    left: 5px;
    border-radius: 50%;
    color: white;
    padding: 16px;
    cursor: pointer;
    z-index: 99;
}
@media screen and (max-width: 768px) {
    .visor_counter {
        width: 20%;
    }
}
@media screen and (max-width: 576px) {
    .visor_counter {
        width: 35%;
    }
}

.ghZAYU {
    width: 65% !important;
}